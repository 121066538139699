import React, { useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { RootState } from '../../../store'
import Spinner from '../../../components/Spinner'
import PageLayout from '../../../layout/PageLayout'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import Alert from '../../../components/Alert'
import {
  getSeriesFilings,
  getSeriesFormD,
  resetError,
  saveSeriesFormD,
} from '../../../features/seriesSlice'
import SeriesFormDDetailForm from './SeriesFormDDetailForm'
import SubmitButton from '../../../components/SubmitButton'

const SeriesFormDDetail: React.FC = () => {
  const { id }: any = useParams()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const { series, error, errorMessage } = useAppSelector(
    (state: RootState) => state.serieses,
  )
  const methods = useForm()

  const { handleSubmit, reset } = methods

  useEffect(() => {
    const getSeriesById = async () => {
      if (+id !== -1) {
        await dispatch(getSeriesFormD({ id }))
        await dispatch(getSeriesFilings(id))
        setLoading(false)
      }
    }
    getSeriesById()
  }, [id, dispatch])

  useEffect(() => {
    reset(series)
  }, [series, reset])

  useEffect(() => () => dispatch(resetError()), [dispatch])

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    const response = await dispatch(saveSeriesFormD({ id, data: { ...data } }))
    if (response && response.payload === 200) {
      await dispatch(getSeriesFormD({ id }))
      await dispatch(getSeriesFilings(id))
      dispatch(resetError())
    }
    setBtnLoading(false)
  }

  const { seriesId, seriesName } = series || {}

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      {error && errorMessage && (
        <Alert
          color="danger"
          visible={error}
          onClose={() => dispatch(resetError())}
          text={errorMessage}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h3>{seriesName?.replace('Linqto Liquidshares LLC -', '')}</h3>
              <h4>Series No. {seriesId}</h4>
            </div>
            <SubmitButton loading={btnLoading} text="Save" />
          </div>
          <SeriesFormDDetailForm
            loading={btnLoading}
            btnLoading={btnLoading}
            setBtnLoading={setBtnLoading}
          />
        </form>
      </FormProvider>
    </PageLayout>
  )
}

export default SeriesFormDDetail
