import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'
import { convertDateToPT, convertUTCDateToPT } from '../utils/formatUtils/utils'

interface SeriesState {
  filtered: null
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  series: any
  error: boolean
  errorMessage: string
  serieses: any[]
}

const initialState: SeriesState = {
  filtered: null,
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  series: {},
  error: false,
  errorMessage: '',
  serieses: [],
}

export const getSerieses = createAsyncThunk(
  'getSerieses',
  async ({ found }: any) => {
    const params = found.queryParams
    try {
      const response = await privateAPI.post(
        '/queries/serieses',
        JSON.parse(params),
      )
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const getSeriesFilings = createAsyncThunk(
  'getSeriesFilings',
  async (id: string) => {
    try {
      const response = await privateAPI.post('/queries/series_filings', {
        seriesFilingDate: {},
        filingDate: {},
        accessionNumber: {},
        seriesId: { filterMin: +id, filterMax: +id },
        note: {},
        seriesFilingId: {},
      })
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const getSeriesFormD = createAsyncThunk(
  'getSeriesFormD',
  async ({ id }: any, { rejectWithValue }) => {
    try {
      const response = await privateAPI.get(`/queries/series_formd_infos/${id}`)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const saveSeriesFormD = createAsyncThunk(
  'saveSeriesFormD',
  async ({ id, data }: any, { rejectWithValue }) => {
    const filingDate = convertDateToPT(data?.filingDate)
    try {
      await privateAPI.post(`/queries/series_formd_infos/${id}`, {
        ...data,
        filingDate,
      })
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const saveSeriesFilings = createAsyncThunk(
  'saveSeriesFilings',
  async (data: any) => {
    try {
      const response = await privateAPI.post(
        `/queries/series_filings/${data.seriesFilingId || '-1'}`,
        data,
      )
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const createBlueSkyFee = createAsyncThunk(
  'createBlueSkyFee',
  async (data: any, { rejectWithValue }) => {
    const filingDate = convertDateToPT(data?.filingDate)
    try {
      await privateAPI.post(`/queries/series_blue_sky_fees/-1`, {
        ...data,
        filingDate,
      })
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const seriesSlice = createSlice({
  name: 'serieses',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = false
      state.errorMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeriesFormD.fulfilled, (state, { payload }: any) => {
        const data = payload.rows[0] || {}
        if (data) {
          let filingDate: Date | null = null
          if (data?.filingDate) {
            filingDate = convertUTCDateToPT(data.filingDate)
          }
          state.series = { ...data, filingDate }
        }
      })
      .addCase(saveSeriesFormD.rejected, (state) => {
        state.error = true
        state.errorMessage = 'There was an error saving this series.'
      })
      .addCase(createBlueSkyFee.rejected, (state) => {
        state.error = true
        state.errorMessage = 'There was an error creating this series.'
      })
      .addCase(getSerieses.fulfilled, (state, { payload }: any) => {
        state.serieses = payload
      })
      .addCase(getSeriesFilings.fulfilled, (state, { payload }: any) => {
        const { rows: filings } = payload || {}
        state.series = { ...state.series, filings }
      })
  },
})

export const { resetError } = seriesSlice.actions

export default seriesSlice.reducer
