import axios from 'axios'
import privateAPI from '../APIs/private'

export const uploadDocs: any = async ({
  acceptedFile,
  type,
  userId,
  entityId = null,
  cashExternalAccountId = null,
  upholdAccessId = null,
}) => {
  const trimmedString = acceptedFile?.name?.substring(0, 100) || ''
  let apiEndPoint = ''
  switch (type) {
    case 'AI':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/accreditationDocuments`
      break
    case 'FA':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/financialAdvisorDocuments`
      break
    case 'ID':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/kycDocuments`
      break
    case 'TAX':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/taxWithholdingAndReportingDocuments`
      break
    case 'ENTITY_TAX':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/queries/ENTITIES/${entityId}/taxWithholdingAndReportingDocuments`
      break
    case 'INTERNAL_DOCUMENT':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/queries/ENTITIES/${entityId}/internalDocuments`
      break
    case 'RIA_FIRM':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/riaFirms/${userId}/Documents`
      break
    case 'INTERNAL':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/internalDocuments`
      break
    case 'USER_TAX':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/taxDocuments`
      break
    case 'USER_UPLOADED':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/userUploadedDocuments`
      break
    case 'CASH_EXTERNAL_ACCOUNT':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/queries/CASH_EXTERNAL_ACCOUNTS/${cashExternalAccountId}/internalDocuments`
      break
    case 'UPHOLD_ACCESSES':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/queries/UPHOLD_ACCESSES/${upholdAccessId}/internalDocuments`
      break
    case 'PROMO_FULFILLMENT':
      apiEndPoint = `${process.env.REACT_APP_API_URL}/importPromoUsers`
      break
    default:
      apiEndPoint = `${process.env.REACT_APP_API_URL}/users/${userId}/accreditationDocuments`
  }
  const data = new FormData()
  data.append('file', acceptedFile)
  data.append('name', trimmedString)
  const config = {
    method: 'post',
    url: apiEndPoint,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: data,
  }

  await axios(config)
}

/**
 * helper to call /queries/ api to return selected parameters
 * @param {string} query name of query that we want to call
 * @param {object} data parameters passed to retrieve data
 * @returns object | undefined
 */
export const getData: any = async (query, data) => {
  const response = await privateAPI.post(`/queries/${query}/`, data)
  return response?.data?.rows?.[0]
}

export const getUser: any = async (id) => {
  const obj = {
    userId: { filterMin: id, filterMax: id },
    fullName: {},
  }
  const response = await privateAPI.post('/queries/users/', obj)
  return response?.data?.rows?.[0] || {}
}

export const getCompanyData = async (id: number): any => {
  const response = await privateAPI.get(`/queries/companies/${id}`)
  return response?.data?.rows?.[0] || {}
}

export const getBrokerInfo = async (id: number): any => {
  const response = await privateAPI.get(`/queries/broker_infos/${id}`)
  return response?.data?.rows?.[0] || {}
}

export const getCashAccount = async (id: number): any => {
  const response = await privateAPI.get(`/queries/cash_accounts/${id}`)
  return response?.data?.rows?.[0] || {}
}

export const getSegmentList = async (data: any): Promise<any> => {
  const response = await privateAPI.post('/queries/segments', data)
  return response.data?.rows || null
}

/**
 * @param {string} query list of the query we want to hit - e.g. 'users', 'companies', 'broker_infos' etc
 * @param {*} data object with the query parameters { userId: {}, fullName: {}, ... }
 * @returns rows from the query (multiple rows or single row)
 */
export const getDataList = async (query: string, data: any): Promise<any> => {
  const response = await privateAPI.post(`/queries/${query}`, data)
  return response.data?.rows || null
}
