import React from 'react'
import { CButton } from '@coreui/react-pro'
import privateAPI from '../../../APIs/private'
import GenericTable from '../../../components/GenericTable'
import { useQuery } from '@tanstack/react-query'
import Loading from './Loading'


const DOCUMENT_COLUMNS = [
  {
    key: 'name',
    name: 'name',
    label: "File Name",
    type: 'String'
  },
  {
    key: 'type',
    name: 'type',
    label: "File Type",
    type: 'String'
  },
  {
    key: 'fileUrl',
    name: 'fileUrl',
    label: "View",
    type: 'Button',
    renderer: 'Button'
  },
  {
    key: 'createdAt',
    name: 'createdAt',
    label: "Created At",
    type: 'Date',
    renderer: 'DateTime'
  },
  {
    key: 'updatedAt',
    name: 'updatedAt',
    label: "Updated At",
    type: 'Date',
    renderer: 'DateTime'
  },
]

interface UserDocumentsProps {
  id: string,
  selectedItems: any[],
  setSelectedItems: (value: any) => any,
  setShowRecategorizeDocumentsModal: (value: any) => any
}

const getUserDocuments = async (id: string) => {
  const data = {
    documentId: {},
    companyId: {},
    orderId: {},
    userId: { filterValues: [id] },
    entityId: {},
    originationId: {},
    riafirmId: {},
    cashExternalAccountId: {},
    upholdAccessId: {},
    segmentId: {},
    name: {},
    type: {
      filterValues: [
        "FINANCIAL_ADVISOR_VIEW",
        "INTERNAL",
        "TAX_WITHHOLDING_AND_REPORTING",
        "USER_TAX",
        "USER_UPLOADED"
      ],
      "sortAsc": false,
      "sortIndex": 1
    },
    fileUrl: {},
    createdAt: {},
    updatedAt: {}
  }
  try {
    const response = await privateAPI.post('/queries/documents', data)
    return response.data
  } catch (e) {
    console.log('error getting documents: ', e)
  }
}


const UserDocuments: React.FC<UserDocumentsProps> = ({
  id,
  selectedItems,
  setSelectedItems,
  setShowRecategorizeDocumentsModal
}: UserDocumentsProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ['user_documents', id],
    queryFn: () => getUserDocuments(id),
  })

  if (isLoading) return <Loading />
  return (
    <div className="mt-4">
      <CButton disabled={selectedItems?.filter((a) => a._selected === true).length === 0} onClick={() => setShowRecategorizeDocumentsModal(true)}>
        Bulk Recategorize</CButton>
      <div>
        <GenericTable
          items={data.rows}
          columns={DOCUMENT_COLUMNS}
          isSelectable={true}
          handleCheckboxClick={setSelectedItems}
        />
      </div>
    </div>
  )
}

export default UserDocuments
