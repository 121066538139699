import { CButton, CFormInput, CModal, CModalBody } from '@coreui/react-pro'
import React, { useState } from 'react'
import { BarLoader } from 'react-spinners'

const MFAModal: React.FC<any> = ({ handleSubmit, handleClose, loading }) => {
  const [mfaText, setMfaText] = useState('')
  return (
    <CModal visible alignment="center" size="sm" onClose={handleClose}>
      <CModalBody>
        {loading ? (
          <div
            className="row g-3 mb-4 d-flex justify-content-center align-items-center"
            style={{ height: '400px' }}
          >
            <BarLoader color="#0039ec" loading height={5} width={200} />
          </div>
        ) : (
          <div className="row g-3 mb-4">
            <h3>Multi-Factor Authentication</h3>
            <p>
              Please enter the MFA code sent to your email or authenticator app
            </p>
            <CFormInput
              value={mfaText}
              placeholder="Enter MFA Code"
              maxLength={6}
              onChange={(e) => setMfaText(e.target.value)}
            />
            <CButton
              disabled={mfaText.length !== 6}
              onClick={() => handleSubmit(mfaText)}
            >
              Submit
            </CButton>
          </div>
        )}
      </CModalBody>
    </CModal>
  )
}

export default MFAModal
